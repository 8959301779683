import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code-snippet"
    }}>{`Code snippet`}</h2>
    <ComponentCode name="Code snippet" component="code-snippet" variation="code-snippet" hasReactVersion="codesnippet--single-line" hasVueVersion="codesnippet--oneline" codepen="vMLEbR" hasAngularVersion="?path=/story/codesnippet--basic" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "inline-code-snippet"
    }}>{`Inline code snippet`}</h2>
    <ComponentCode name="Inline code snippet" component="code-snippet" variation="code-snippet--inline" hasLightVersion hasReactVersion="codesnippet--inline" hasVueVersion="codesnippet--inline" hasAngularVersion="?path=/story/codesnippet--inline" codepen="LvGVYe" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "multi-line-code-snippet"
    }}>{`Multi line code snippet`}</h2>
    <ComponentCode name="Multi line code snippet" component="code-snippet" variation="code-snippet--multi" hasReactVersion="codesnippet--multi-line" hasVueVersion="codesnippet--multiline" hasAngularVersion="?path=/story/codesnippet--multi" codepen="ZZQGGO" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="code-snippet" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      